<template>
    <div>内页</div>
</template>

<script>
    export default {
        name: "Inside"
    }
</script>

<style scoped>

</style>